import { trpc } from "@/trpc"
import type { OrganizationData } from "@/typings"
import { useProvider } from "@/utils/providers"
import { getSelectedOrg } from "@/utils/utils.ts"
import type { AxiosError } from "axios"
import { useEffect, useState, type FC, type PropsWithChildren } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Loader from "../../../components/Loader/Loader"
import RabbitSVG from "../../../svg/rabbit"
import Details from "./Details"

const useAppStatus = () => {
	const [appStatus, setAppStatus] = useState<boolean>(false)
	const [loader, setLoader] = useState<boolean>(true)
	const [loaderMessage, setLoaderMessage] = useState<string>("")

	const navigate = useNavigate()
	const trpcUtils = trpc.useUtils()

	const { isGitlab, isAzureDevops } = useProvider()

	useEffect(() => {
		setLoader(true)
		void fetchData()
		if (sessionStorage.getItem("app-status") === "installed") {
			setAppStatus(true)
		}
	}, [appStatus])

	const fetchData = async () => {
		try {
			setLoaderMessage("Loading your data... 🚀")
			await getAppStatus()
		} catch (error) {
			setLoader(false)
			toast.error("Something went wrong, Please try again")
		}
	}

	const getAppStatusApi = async () => {
		const selectedOrg = getSelectedOrg()
		if (!selectedOrg) {
			throw new Error("Selected org not found")
		}
		const data = await trpcUtils.appStatus.getAppStatus.fetch({
			user_name: selectedOrg.organization_name,
		})
		if (data.status === 200 || data.status === 404) {
			return data
		} else {
			throw new Error("Unexpected response status")
		}
	}

	const getAppStatus = async (checked_orgs: string[] = []) => {
		if (isGitlab || isAzureDevops) {
			// no check for app status in case of gitlab, as we are not installing it for groups aka orgs
			setAppStatus(true)
			sessionStorage.setItem("app-status", "installed")
			setLoader(false)
			return
		}
		const selectedOrg = getSelectedOrg()
		const selectedOrgId = selectedOrg?.provider_organization_id
		if (selectedOrgId) {
			checked_orgs.push(selectedOrgId)
		}
		try {
			const response = await getAppStatusApi()
			if (response.status === 200) {
				sessionStorage.setItem("app-status", "installed")
				setAppStatus(true)
				setLoader(false)
			} else if (
				response.status === 404 &&
				response.message === "App not installed"
			) {
				const orgInfo = JSON.parse(sessionStorage.getItem("org_info")!) as
					| OrganizationData[]
					| null
				if (orgInfo) {
					for (const item of orgInfo) {
						if (
							!checked_orgs.includes(item.provider_organization_id) &&
							!item.parentId
						) {
							sessionStorage.setItem("selected_org", JSON.stringify(item))
							sessionStorage.setItem("org_id", item.id)
							checked_orgs.push(item.provider_organization_id)
							await getAppStatus(checked_orgs)
							return
						}
					}
				}
				// We are here means, we are not able to find any org where app is not installed. lets redirect
				window.location.assign(import.meta.env.VITE_GITHUB_APP_URL)
				sessionStorage.removeItem("app-status")
				return
			} else {
				throw new Error("Unexpected 404 response")
			}
		} catch (error) {
			if ((error as AxiosError).response?.status == 401) {
				sessionStorage.clear()
				navigate("/login")
				return
			}

			setAppStatus(false)
			sessionStorage.removeItem("app-status")
			setLoader(false)
			console.log(error)
		}
	}

	return { appStatus, setAppStatus, loader, loaderMessage }
}

export const DashboardPageWrapper: FC<PropsWithChildren> = ({ children }) => {
	const { appStatus, loader, loaderMessage } = useAppStatus()
	return (
		<>
			{loader ? (
				<Loader message={loaderMessage} />
			) : (
				<div className="overflow-hidden pb-2 pt-7 sm:px-4 lg:px-8">
					{!appStatus ? (
						<a href={import.meta.env.VITE_GITHUB_APP_URL}>
							<div className="container mx-auto my-10 cursor-pointer rounded-2xl border-2 bg-white p-20 text-center">
								<div className="text-center text-[#24242]">
									<div className="flex items-center justify-center">
										<RabbitSVG width={100} height={100} />
									</div>
									<div>
										<h1 className="mt-5 text-[#24242]">
											Click here to install CodeRabbit on your organization and
											choose for which repositories you&apos;d like code reviews
											to occur on. Got more questions? Head over to the{" "}
											<em>Get Started</em> section <strong>under</strong> the{" "}
											<em>Docs</em> tab for more detailed guidance.
										</h1>

										<h1 className="mt-5 text-[#24242]">
											After connecting, you&apos;ll have access to code review
											metrics and a history of pull requests involving
											CodeRabbit.
										</h1>
									</div>
								</div>
							</div>
						</a>
					) : (
						<div className="flex flex-wrap">
							<div className="w-full px-4">
								<div className="font-500 mb-2 font-inter text-2xl leading-8 text-foreground">
									Dashboard
								</div>
								{children}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	)
}

export function LegacyDashboard() {
	return (
		<DashboardPageWrapper>
			<Details />
		</DashboardPageWrapper>
	)
}
